import React, { useEffect, useState } from "react"
import { graphql, Link } from 'gatsby';
import {
  Layout,
  SEO,
  FacilityAbout,
  Premiumlist,
  Facilitieslist
} from '../../components/_index';
import { facilityGet } from '../../utils/facility-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  return (
    <Layout>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <main id="facility" className="under">
        <div id="facilityMv" className="underMv">
          <div className="mvTit"><div className="mvTitIn">
            <h2>施設一覧</h2>
            <p wovn-ignore="true">Houses list</p>
          </div></div>
        </div>
        <FacilityAbout />
        <div className="facilitieslistWrap">
          <div id="facilitieslistNav" className="sp">
            <ul>
              {facilityGet().map((item, index) => {
                return (
                  <li key={index}><a href={`#${item.id}`}><span className={`number ${item.premium ? 'premium' : ''}`}>{item.number}</span>{item.short_title}</a></li>
                )
              })}
            </ul>
            <div className="mapBtn"><a href="#frontMap">地図を見る</a></div>
          </div>
          <div id="betteilistWrap">
            <div className="brackets topBrackets"></div>
            <ul id="betteilist">
              <Premiumlist data={facilityGet().filter((item) => {
                return item.premium
              })} />
            </ul>
            <div className="brackets bottomBrackets"></div>
          </div>
          <ul id="facilitieslist">
            <Facilitieslist data={facilityGet().filter((item) => {
              return !item.premium
            })} />
          </ul>
        </div>
        <div id="frontMap" className="map">
          <div id="jaMap" className="map">
            <div className="iframe-wrap">
              <iframe src="https://www.google.com/maps/d/embed?mid=1Rc_hsag2ZR38zUsX3ExlEVxG32ql0e67"></iframe>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default SubPage;


export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
