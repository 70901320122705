import { useStaticQuery, graphql } from 'gatsby';

export const facilityGet = () => {

  return [
    {
      "premium": true,
      "title": "京の温所 西陣別邸（プレミアム）",
      "short_title": "西陣別邸",
      "id": "nishijin-villa",
      "number": "#5",
      "img": "/images/facility/20220611nishimv.jpg",
      "sent": "建築家 中村好文と「ミナ ペルホネン」皆川明が手がける京の別邸",
      "capacity": "1～8名",
      "sightseeing": "花街上七軒(徒歩約7分)、晴明神社(徒歩約約8分)、北野天満宮(徒歩約12分)",
      "maplink": "https://goo.gl/maps/39rECvwSFwV3Cam1A",
      "address": "京都市上京区浄福寺通今出川下る竪亀屋町265-1",
      "roomcd": '0000001669'
    },
    {
      "premium": false,
      "title": "京の温所 釜座二条",
      "short_title": "釜座二条",
      "id": "kamanza-nijo",
      "number": "#2",
      "img": "/images/facility/Kamanza_22.jpg",
      "sent": "建築家 中村好文と「ミナ ペルホネン」皆川明が手がける現代生活に寄り添う「住まい」としての京町家",
      "capacity": "1～4名",
      "sightseeing": "御金神社(徒歩約3分)、二条城(徒歩約6分)、京都御苑(徒歩約14分)",
      "maplink": "https://goo.gl/maps/eTCVHi3SguA2",
      "address": "京都市中京区釜座通二条下る上松屋町690-2",
      "roomcd": '0000001666'
    },
    {
      "premium": false,
      "title": "京の温所 御幸町夷川",
      "short_title": "御幸町夷川",
      "id": "gokomachi-ebisugawa",
      "number": "#3",
      "img": "/images/facility/gokomachi20210919.jpg",
      "sent": "用と美 「飲む」「食べる」ひとときのための道具や骨董品が揃う風情ただよう、茶房のような京町家",
      "capacity": "1～4名",
      "sightseeing": "京都御苑(徒歩約8分)、下御霊神社(徒歩約4分)",
      "maplink": "https://goo.gl/maps/EzSG3t5sMcB2",
      "address": "京都市中京区御幸町通夷川上る松本町574",
      "roomcd": '0000001667'
    },
    {
      "premium": false,
      "title": "京の温所 麩屋町二条",
      "short_title": "麩屋町二条",
      "id": "fuyacho-nijo",
      "number": "#4",
      "img": "/images/facility/1460fd709ac86883baf461f40aeeae9f.jpg",
      "sent": "伝統文化が息づく京町家と「カリモク家具」、大人数でゆったりと滞在できる宿",
      "capacity": "1～8名",
      "sightseeing": "京都御苑(徒歩約8分)、下御霊神社(徒歩約6分)",
      "maplink": "https://goo.gl/maps/248fFseDj4XwBFRu7",
      "address": "京都市中京区麩屋町通二条上る布袋屋町502",
      "roomcd": '0000001668'
    },
    {
      "premium": false,
      "title": "京の温所 御所西",
      "short_title": "御所西",
      "id": "goshonishi",
      "number": "#6",
      "img": "/images/facility/23a2c8a8979674ae18aff2e90daf9c3a.jpg",
      "sent": "クリエイティブユニット「graf」が手がける光と影、季節を豊かに感じる京町家",
      "capacity": "1～4名",
      "sightseeing": "京都御苑(徒歩約5分) 、護王神社(徒歩約6分)、二条城(徒歩約15分)",
      "maplink": "https://goo.gl/maps/pjypSSEbJdYppm7A8",
      "address": "京都市上京区衣棚通椹木町上る門跡町291",
      "roomcd": '0000001670'
    },
    {
      "premium": false,
      "title": "京の温所 丸太町",
      "short_title": "丸太町",
      "id": "marutamachi",
      "number": "#7",
      "img": "/images/facility/20220611marumv.jpg",
      "sent": "京町家で美とアートに触れる時間と空間を共有できる女子旅の宿",
      "capacity": "1～4名",
      "sightseeing": "京都御苑(徒歩約3分) 、二条城(徒歩約12分) 、護王神社(徒歩約10分) 、御金神社(徒歩約11分) ",
      "maplink": "https://goo.gl/maps/hhK6C2AKsuN5LBgo6",
      "address": "京都市中京区車屋町通夷川上る少将井御旅町341-2",
      "roomcd": '0000001671'
    },
    {
      "premium": false,
      "title": "京の温所 竹屋町",
      "short_title": "竹屋町",
      "id": "takeyamachi",
      "number": "#8",
      "img": "/images/facility/bd449d56e12099d708bc16244c7202cb.jpg",
      "sent": "京町家の原点である「職住一体」の暮らしを体験できる宿",
      "capacity": "1～4名",
      "sightseeing": "京都御苑(徒歩約6分) 、二条城(徒歩約6分) 、護王神社(徒歩約11分) 、御金神社(徒歩約8分) ",
      "maplink": "https://goo.gl/maps/1vVPYv2SzTxrZWcn9",
      "address": "京都市中京区竹屋町通西洞院東入指物屋町371",
      "roomcd": '0000001672'
    }
  ]
}
